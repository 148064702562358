@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Font */
@font-face {
  font-family: 'Centra';
  src: url('./assets/font/CentraNo2-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Centra';
  src: url('./assets/font/CentraNo2-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Centra';
  src: url('./assets/font/CentraNo2-Book.ttf') format('truetype');
  font-weight: 400;
}

/* Reset & Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Centra', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
}

/* Links */
a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Input Fields */
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .App {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 620px) {
  .App {
    width: 100%;
    position: relative;
  }
}